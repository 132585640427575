//#const blankImage = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAWElEQVR42mNkAAIAAAoAAv/lxKUAAAAASUVORK5CYII=';
const blankImage = require('@/assets/blank.png');

import { openDB } from 'idb';
let dbPromise = openDB('ImageDB', 1, {
    upgrade(db) {
        db.createObjectStore('images', { keyPath: 'id' });
    },
});

export const state = {
    db: null,
    imageHash: {},
};
export const actions = {
    async loadImage({ state, commit }, id) {
        if (!id || id === '-1' || id === -1 || id === '0' || id === 0) {
            return blankImage;
        }
        if (state.imageHash[id]) {
            return state.imageHash[id];
        }
        try {
            const inDB = await (await dbPromise).get('images', id);
            if (inDB && inDB.data) {
                commit('setCacheImage', [inDB.data, id]);
                return URL.createObjectURL(inDB.data);
            }
        } catch (e) { 
            console.error(e);
            return blankImage;
        }
        try {
            const response = await fetch(`${process.env.VUE_APP_API_SERVER}productImageID.php?id=${id}`);
            // for (let [key, value] of response.headers.entries()) {
            //     console.log(`${key}: ${value}`);
            // }
            //console.log("Header ", response.headers.get('last-modified'), " ", response.headers.get('expires'));
            if (
                response.headers.get('last-modified') === 'Thu, 1 Nov 1981 01:01:01 GMT' &&
                response.headers.get('expires') === 'Thu, 1 Nov 1981 00:00:00 GMT'
            ) {
                return blankImage;
            }
            const blob = await response.blob();
            try {
                await dbPromise.then(async db => {
                    const tx = db.transaction('images', 'readwrite');
                    const store = tx.objectStore('images');
                    await store.add({ id, data: blob });
                });
            } catch (e) {
                console.error(e);
            }

            commit('setCacheImage', [blob, id]);
            return URL.createObjectURL(blob);
        } catch (e) { 
            console.error(e);
            return blankImage;
        }
    },
};
export const mutations = {
    setCacheImage(state, args) {
        state.imageHash[args[1]] = URL.createObjectURL(args[0]);
    },
};
