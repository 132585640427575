import {baseDataService} from "@/services/baseData.service";

export const state = {
    selectedCustomer:{},
    additionalDetails:{},
    savedSalesOrder:{},
    items:[] ,
    offlineOrders:[]
}


export const actions = {
    async createSalesOrder({state, commit}) {
        try { 
            let DetailsLines = state.items.map(x=>{
                                            let row = {"productid":x.productid,"quantity":x.quantity,"comment":x.comment||""};
                                            let product = JSON.parse(localStorage.getItem(x.productid));
                                            if(product){ 
                                                row.Price =product.Price;   
                                            }
                                            return row;
                                        });
             
            let additionalDetails = Object.assign({...state.savedSalesOrder},state.additionalDetails);
             
            additionalDetails.StickerMed = additionalDetails.StickerMed||"No"=="Yes"?1:0;
            additionalDetails.StickerSmall = additionalDetails.StickerSmall||"No"=="Yes"?1:0;
            additionalDetails.HangLabels = additionalDetails.HangLabels||"No"=="Yes"?1:0;
            let order = null;
            debugger;
            if(state?.savedSalesOrder?.idsalsOrderHeader){
                order = await baseDataService.updateSalesOrder(state.savedSalesOrder.idsalsOrderHeader,state.selectedCustomer.idCustomerMaster,
                    DetailsLines,
                    additionalDetails);

                if(order.OK=="OK"){
                    this.commit("shoppyCart/_clearCart");
                }
            }else{

                order = await baseDataService.createSalesOrder(state.selectedCustomer.idCustomerMaster,
                                                                DetailsLines,
                                                                additionalDetails);
                
                if(order.OK=="OK"){
                    this.commit("shoppyCart/_clearCart");
                }            
            }
            return order;
        } catch (error) {
             
            commit('offlineOrder');   
            return {"OK":"NOK",message:"off line"};         
        } 
    }
}

export const mutations = {
    loadCustomer(state){
        let customer = JSON.parse(localStorage.getItem("cart_active_customer"));
        if(customer){
            state.selectedCustomer =customer;
            return;
        }
        state.selectedCustomer=null;
    },
    loadItems(state){
        let items = JSON.parse(localStorage.getItem("cart_items"));
        if(items){
            state.items =items;
            return;
        }
        state.items =[];
    },
    loadAdditionalDetails(state){
        let additionalDetails = JSON.parse(localStorage.getItem("cart_additionalDetails"));
        if(additionalDetails){
            state.additionalDetails =additionalDetails;
            return;
        }
        state.additionalDetails={};
    },
    loadSavedSalesOrder(state){
        let savedSalesOrder = JSON.parse(localStorage.getItem("cart_savedSalesOrder"));
        if(savedSalesOrder){
            state.savedSalesOrder =savedSalesOrder;
            return;
        }
        state.savedSalesOrder={};
    },
    loadOfflineOrders(state){
        state.offlineOrders =[];
        let offlineList = JSON.parse(localStorage.getItem("offline"));
        if(!offlineList){
            offlineList = [];
        }
        offlineList.forEach(element => {
            let offlineOrder = JSON.parse(localStorage.getItem(element));
            if(offlineOrder){
                offlineOrder.key = element;
                offlineOrder.Name = offlineOrder.customer.Name;
                offlineOrder.Items = offlineOrder.items.length;
                state.offlineOrders.push(offlineOrder);
            }
        });
    },
    convertOfflineToCart(state,Index){
        let key = state.offlineOrders[Index].key;
        let offLineData = JSON.parse(localStorage.getItem(key));

        state.selectedCustomer =offLineData.customer;
        state.items = offLineData.items;
        state.additionalDetails = offLineData.additionalDetails;
        localStorage.removeItem(key);

        state.offlineOrders.splice(Index,1);
        let allKeys = state.offlineOrders.map(x=>x.key);
        localStorage.setItem("offline",JSON.stringify(allKeys));
        this.commit("shoppyCart/_saveItemState") 
    },
    convertOrder(state,order){
        debugger;
        state.savedSalesOrder = order;
        state.selectedCustomer =order.customer;
        state.items = order.details.map(x=>({"idsalesOrderDetails":x.idsalesOrderDetails,"productid": x.idproduct,"quantity": Number(Number(x.Quantity).toFixed()),"comment": x.Comment}));
        let additionalDetails = {
            idsalsOrderHeader:order.idsalsOrderHeader,
            customerPO:order.CustomerPO,
            DeliveryType:order.DeliveryType,
            addressOverride:"customer",
            AddressLine1:order.ShipAddressLine1,
            AddressLine2:order.ShipAddressLine2,
            AddressLine3:order.ShipAddressLine3,
            AddressLine4:order.ShipAddressLine4,
            AddressLine5:order.ShipAddressLine5,
            StickerMed:order.StickerMed?"Yes":"No",
            StickerSmall:order.StickerSmall?"Yes":"No",
            HangLabels:order.HangLabels?"Yes":"No",
        }
        state.additionalDetails = additionalDetails;
        this.commit("shoppyCart/_saveItemState") 
    },
    setCustomer(state,customer){
        state.selectedCustomer =customer;
        localStorage.setItem("cart_active_customer",JSON.stringify(customer));
    },

    /**
     * Description
     * @param {any} state
     * @param {any} Product {"productid": idproduct,"quantity": qty,"comment": comment}
     * @returns {any}
     */
    addItem(state,Product){
        state.items.push(Product);
        this.commit("shoppyCart/_saveItemState") 
    },
    deleteItem(state,Index){
        state.items.splice(Index,1);
        this.commit("shoppyCart/_saveItemState") 
    },
    updateItem(state,args){        
        if(args[1]){
            state.items[args[0]] = args[1];
        }        
        this.commit("shoppyCart/_saveItemState") 
    },

    /**
     * Description
     * @param {any} state
     * @param {any} AdditionalDetails {
                            customerPO:this.form.customerPO,
                            DeliveryType:this.form.DeliveryType,
                            addressOverride:this.form.addressOverride,
                            AddressLine1:this.form.AddressLine1,
                            AddressLine2:this.form.AddressLine2,
                            AddressLine3:this.form.AddressLine3,
                            AddressLine4:this.form.AddressLine4,
                            AddressLine5:this.form.AddressLine5,
                            StickerMed:this.form.StickerMed,
                            StickerSmall:this.form.StickerSmall,
                            HangLabels:this.form.HangLabels,
                        }
     * @returns {any}
     */
    setAdditionalDetails(state,AdditionalDetails){
        
        state.additionalDetails = AdditionalDetails;
        this.commit("shoppyCart/_saveItemState") 
    },
    setSavedSalesOrder(state,SavedSalesOrder){
        
        state.savedSalesOrder = SavedSalesOrder;
        this.commit("shoppyCart/_saveItemState") 
    },

    _clearCart(state){
        state.selectedCustomer=null;
        state.savedSalesOrder=null;
        state.additionalDetails={};
        state.items =[];
        this.commit("shoppyCart/_saveItemState");
        this.commit("productView/clear");
    },
    _saveItemState(state){ 
        localStorage.setItem("cart_items",JSON.stringify(state.items));
        localStorage.setItem("cart_active_customer",JSON.stringify(state.selectedCustomer));
        localStorage.setItem("cart_additionalDetails",JSON.stringify(state.additionalDetails));
        localStorage.setItem("cart_savedSalesOrder",JSON.stringify(state.savedSalesOrder));
    },
    offlineOrder(state){
        let offlineOrder = {"customer":state.selectedCustomer,"items":state.items,"additionalDetails":state.additionalDetails}
        let key = "offline_"+state.selectedCustomer.idCustomerMaster;
        localStorage.setItem(key,JSON.stringify(offlineOrder));
        let listOfOfflineOrder = JSON.parse(localStorage.getItem("offline"));
        if(!listOfOfflineOrder){
            listOfOfflineOrder = [];
        }
        listOfOfflineOrder.push(key);
        localStorage.setItem("offline",JSON.stringify(listOfOfflineOrder));
        
        this.commit("shoppyCart/_clearCart") 
    }
}